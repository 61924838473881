import Swal from "sweetalert2"
import { useTranslate } from "./hooks/translate.hook"

export function formatPermalink(payload: string) {
    return payload.toLocaleLowerCase().trim()
        .replaceAll(' ', '-')
        .replaceAll('?', '')
        .replaceAll(',', '')
        .replaceAll('.', '')
        .replaceAll('/', '')
        .replaceAll('=', '')
        .replaceAll('!', '')
        .replaceAll('@', '')
        .replaceAll('#', '')
        .replaceAll('$', '')
        .replaceAll('%', '')
        .replaceAll('^', '')
        .replaceAll('&', '')
        .replaceAll('*', '')
        .replaceAll('(', '')
        .replaceAll(')', '')
        .replaceAll('_', '-')
        .replaceAll('+', '')
        .replaceAll(':', '')
        .replaceAll(';', '')
        .replaceAll('\'', '')
        .replaceAll('\"', '')
        .replaceAll('[', '')
        .replaceAll(']', '')
        .replaceAll('{', '')
        .replaceAll('}', '')
        .replaceAll('|', '')
        .replaceAll('<', '')
        .replaceAll('>', '')
        .replaceAll('ć', 'c')
        .replaceAll('č', 'c')
        .replaceAll('š', 's')
        .replaceAll('ž', 'z')
        .replaceAll('đ', 'dj')
}

const sab = Swal.mixin({
    customClass: {
        confirmButton: 'btn btn-primary m-1',
        cancelButton: 'btn btn-secondary m-1',
        denyButton: 'btn btn-secondary m-1',
        popup: 'card text-body'
    },
    buttonsStyling: false
})

const t = useTranslate()
export async function showLoading() {
    return await sab.fire({
        title: t('alert.loading.0'),
        text: t('alert.loading.1'),
        showCloseButton: false,
        showConfirmButton: false,
        showCancelButton: false,
        allowOutsideClick: false
    })
}

export async function showSuccess(message: string) {
    return await sab.fire({
        title: t('alert.success'),
        text: message,
        icon: 'success',
        confirmButtonText: t('alert.accept')
    })
}

export async function showError(message: string) {
    return await sab.fire({
        title: t('alert.error'),
        text: message,
        icon: 'error',
        confirmButtonText: t('alert.accept')
    })
}

export async function showWarning(title: string, desc: string) {
    return await sab.fire({
        title: title,
        text: desc,
        icon: "warning"
    })
}

export function showConfirm(title: string, desc: string, callback: Function): void {
    sab.fire({
        title: title,
        text: desc,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: t('general.yes'),
        cancelButtonText: t('general.no'),
        reverseButtons: true
    }).then((result) => {
        if (result.isConfirmed) {
            callback();
        }
    })
}

export async function closeAnimation() {
    await Swal.close()
}

export const formatDate = (date: Date) => {
    return date.toLocaleString('sr', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    })
}