import { ActiveModel } from "@/models/active.model"
import { MainService } from "./main.service"

export class TagService {
    public static async getAllTags(search: string = '') {
        return await MainService.useAxios(`/tag?search=${search}`)
    }

    public static async getTagById(id: number) {
        return await MainService.useAxios(`/tag/${id}`)
    }

    public static async createTag(payload: Object) {
        return await MainService.useAxios('/tag', 'post', payload)
    }
    public static async toggleTag(payload: ActiveModel) {
        return await MainService.useAxios('/tag/toggle', 'put', payload)
    }

    public static async updateTag(id: number, payload: Object) {
        return await MainService.useAxios('/tag/' + id, 'put', payload)
    }
}