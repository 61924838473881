<template>
    <Navigation />
    <div class="tag">
        <h3>{{ t('tag.title') }}</h3>
        <Search v-model="search" @change="loadTags">
            <RouterLink to="/tag/new" class="btn btn-primary">
                <i class="fa-solid fa-plus"></i>&nbsp;{{ t('tag.add') }}
            </RouterLink>
        </Search>
        <table class="table table-striped table-hover mt-3 mb-3" v-if="tags">
            <thead>
                <tr>
                    <th scope="col">{{ t('tag.table.id') }}</th>
                    <th scope="col">{{ t('tag.table.name') }}</th>
                    <th scope="col">{{ t('tag.table.permalink') }}</th>
                    <th scope="col">{{ t('tag.table.active') }}</th>
                    <th scope="col">{{ t('tag.table.action') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="tag in tags">
                    <th scope="row">{{ tag.tagId }}</th>
                    <td>{{ tag.name }}</td>
                    <td>{{ tag.permalink }}</td>
                    <Switch v-model="tag.active" @change="toggleTag(tag)" />
                    <td>
                        <RouterLink :to="`/tag/edit/${tag.tagId}`" class="btn btn-primary">
                            <i class="fa-solid fa-pen-to-square"></i>&nbsp;{{ t('general.edit') }}
                        </RouterLink>
                    </td>
                </tr>
            </tbody>
        </table>
        <Loading v-else />
    </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import Loading from '@/components/Loading.vue'
import Navigation from '@/components/Navigation.vue';
import { TagModel } from '@/models/tag.model';
import { TagService } from '@/services/tag.service';
import Search from '@/components/Search.vue';
import Switch from '@/components/Switch.vue';
import { useTranslate } from '@/hooks/translate.hook';
import { closeAnimation, showLoading } from '@/utils';

const t = useTranslate()
const tags = ref<TagModel[]>()
const search = ref<string>('')
function loadTags() {
    TagService.getAllTags(search.value)
        .then(rsp => tags.value = rsp.data)
}

async function toggleTag(tag: TagModel) {
    showLoading()
    await TagService.toggleTag({ id: tag.tagId, active: tag.active })
    closeAnimation()
}

onMounted(() => loadTags())
</script>