<template>
    <Navigation />
    <div class="tag">
        <h3>{{ t('tag.edit') }}</h3>
        <RouterLink to="/tag" class="btn btn-danger">
            <i class="fa-solid fa-arrow-left"></i>&nbsp;{{ t('tag.return') }}
        </RouterLink>
        <div class="mt-3 mb-3" v-if="tag">
            <div class="mb-3">
                <label for="idx" class="form-label">{{ t('tag.input.id') }}</label>
                <input type="number" class="form-control" id="idx" v-model="tag.tagId" disabled>
            </div>
            <div class="mb-3">
                <label for="name" class="form-label">{{ t('tag.input.name') }}</label>
                <input type="text" class="form-control" id="name" v-model="tag.name">
            </div>
            <div class="mb-3">
                <label for="name" class="form-label">{{ t('tag.input.permalink') }}</label>
                <input type="text" class="form-control" id="name" v-model="tag.permalink">
            </div>
            <div class="mb-3">
                <label for="kw" class="form-label">{{ t('tag.input.keywords') }}</label>
                <input type="text" class="form-control" id="kw" v-model="tag.keywords">
            </div>
            <div class="mb-3">
                <label for="desc" class="form-label">{{ t('tag.input.desc') }}</label>
                <input type="text" class="form-control" id="desc" v-model="tag.description">
            </div>
            <div class="mb-3 form-check">
                <input type="checkbox" class="form-check-input" id="visible" v-model="tag.active">
                <label class="form-check-label" for="visible">{{ t('tag.input.active') }}</label>
            </div>
            <SaveButton :action="updateTag" />
        </div>
    </div>
</template>

<script lang="ts" setup>
import Navigation from '@/components/Navigation.vue';
import SaveButton from '@/components/SaveButton.vue';
import { useTranslate } from '@/hooks/translate.hook';
import { TagModel } from '@/models/tag.model';
import { TagService } from '@/services/tag.service';
import { closeAnimation, showError, showLoading } from '@/utils';
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const t = useTranslate()
const router = useRouter();
const route = useRoute()
const id = Number.parseInt(route.params.id.toString())
const tag = ref<TagModel>()

TagService.getTagById(id).then(rsp => {
    tag.value = rsp.data
})

function updateTag() {
    if (tag.value == undefined) return
    showLoading()

    if (tag.value.permalink == undefined || tag.value.permalink == '') {
        showError(t('tag.error.permalink'))
        return
    }

    if (tag.value.name == undefined || tag.value.name == '') {
        showError(t('tag.error.name'))
        return
    }

    if (tag.value.description == undefined || tag.value.description == '') {
        showError(t('tag.error.desc'))
        return
    }

    if (tag.value.keywords == undefined || tag.value.keywords == '') {
        showError(t('tag.error.keywords'))
        return
    }

    TagService.updateTag(id, {
        permalink: tag.value.permalink,
        name: tag.value.name,
        description: tag.value.description,
        keywords: tag.value.keywords,
        active: tag.value.active
    }).then(rsp => {
        closeAnimation()
        router.push({
            path: '/tag'
        })
    }).catch(e => alert(e.message))
}
</script>