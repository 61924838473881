<template>
    <Navigation />
    <div class="image">
        <h3>{{ t('image.title') }}</h3>
        <Search v-model="search" @change="loadImages">
            <RouterLink to="/image/new" class="btn btn-primary">
                <i class="fa-solid fa-plus"></i>&nbsp;{{ t('image.add') }}
            </RouterLink>
        </Search>
        <table class="table table-striped table-hover mt-3 mb-3" v-if="images">
            <thead>
                <tr>
                    <th scope="col">{{ t('image.table.img') }}</th>
                    <th scope="col">{{ t('image.table.name') }}</th>
                    <th scope="col">{{ t('image.table.active') }}</th>
                    <th scope="col">{{ t('image.table.action') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="image in images" :key="image.imageId">
                    <th scope="row">
                        <img :src="base + image.local" width="40px">
                    </th>
                    <td>{{ image.name }}</td>
                    <Switch v-model="image.active" @change="toggleImage(image)" />
                    <td>
                        <button type="button" class="btn btn-primary" @click="copyLink(image)">
                            <i class="fa-solid fa-copy"></i>&nbsp;{{ t('general.copy') }}
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <Loading v-else />
    </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import Loading from '@/components/Loading.vue'
import { ImageModel } from '@/models/image.model';
import Navigation from '@/components/Navigation.vue';
import { ImageService } from '@/services/image.service';
import Switch from '@/components/Switch.vue';
import Search from '@/components/Search.vue';
import { useTranslate } from '@/hooks/translate.hook';
import { closeAnimation, showLoading } from '@/utils';

const t = useTranslate()
const base = process.env.VUE_APP_IMAGE_BASE_URL
const images = ref<ImageModel[]>()
const search = ref<string>('')
function loadImages() {
    ImageService.getAllImages(search.value)
        .then(rsp => images.value = rsp.data)
}

async function toggleImage(image: ImageModel) {
    showLoading()
    await ImageService.toggleImage({ id: image.imageId, active: image.active })
    closeAnimation()
}

function copyLink(image: ImageModel) {
    navigator.clipboard.writeText(base + image.local);
}

onMounted(() => loadImages())
</script>