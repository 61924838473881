<template>
  <Navigation />
  <div class="home">
    <h5>{{ t('home.list') }}</h5>
    <ul>
      <li>
        <RouterLink to="/post">{{ t('home.post.title') }}</RouterLink>
        <ul>
          <li>
            <RouterLink to="/post">{{ t('home.post.list') }}</RouterLink>
          </li>
          <li>
            <RouterLink to="/post/new">{{ t('home.post.new') }}</RouterLink>
          </li>
        </ul>
      </li>
      <li>
        <RouterLink to="/category">{{ t('home.category.title') }}</RouterLink>
        <ul>
          <li>
            <RouterLink to="/category">{{ t('home.category.list') }}</RouterLink>
          </li>
          <li>
            <RouterLink to="/category/new">{{ t('home.category.new') }}</RouterLink>
          </li>
        </ul>
      </li>
      <li>
        <RouterLink to="/tag">{{ t('home.tag.title') }}</RouterLink>
        <ul>
          <li>
            <RouterLink to="/tag">{{ t('home.tag.list') }}</RouterLink>
          </li>
          <li>
            <RouterLink to="/tag/new">{{ t('home.tag.new') }}</RouterLink>
          </li>
        </ul>
      </li>
      <li>
        <RouterLink to="/author">{{ t('home.author.title') }}</RouterLink>
        <ul>
          <li>
            <RouterLink to="/author">{{ t('home.author.list') }}</RouterLink>
          </li>
          <li>
            <RouterLink to="/author/new">{{ t('home.author.new') }}</RouterLink>
          </li>
        </ul>
      </li>
      <li>
        <RouterLink to="/image">{{ t('home.image.title') }}</RouterLink>
        <ul>
          <li>
            <RouterLink to="/image">{{ t('home.image.list') }}</RouterLink>
          </li>
          <li>
            <RouterLink to="/image/new">{{ t('home.image.new') }}</RouterLink>
          </li>
        </ul>
      </li>
      <li>
        <RouterLink to="/short">{{ t('home.short.title') }}</RouterLink>
        <ul>
          <li>
            <RouterLink to="/short">{{ t('home.short.list') }}</RouterLink>
          </li>
          <li>
            <RouterLink to="/short/new">{{ t('home.short.new') }}</RouterLink>
          </li>
        </ul>
      </li>
    </ul>
    <h5>{{ t('home.info') }}</h5>
    <ul>
      <li>{{ t('home.domain') }}&nbsp;<span class="fw-bold">{{ domain }}</span></li>
      <li>{{ t('home.size') }}&nbsp;<span class="fw-bold">{{ res }}</span></li>
      <li>{{ t('home.base') }}&nbsp;<span class="fw-bold">{{ base }}</span></li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import Navigation from '@/components/Navigation.vue';
import { useTranslate } from '@/hooks/translate.hook';

const t = useTranslate()
const domain = process.env.VUE_APP_DOMAIN_NAME
const res = process.env.VUE_APP_RECOMMENDED_IMAGE_RES
const base = process.env.VUE_APP_IMAGE_BASE_URL
</script>
