<template>
    <Navigation />
    <div class="short">
        <h3>{{ t('short.title') }}</h3>
        <Search>
            <RouterLink to="/short/new" class="btn btn-primary">
                <i class="fa-solid fa-plus"></i>&nbsp;{{ t('short.add') }}
            </RouterLink>
        </Search>
        <table class="table table-striped table-hover mt-3 mb-3" v-if="shorts">
            <thead>
                <tr>
                    <th scope="col">{{ t('short.table.id') }}</th>
                    <th scope="col">{{ t('short.table.short') }}</th>
                    <th scope="col">{{ t('short.table.to') }}</th>
                    <th scope="col">{{ t('short.table.active') }}</th>
                    <th scope="col">{{ t('short.table.action') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="link in shorts">
                    <th scope="row">{{ link.shortUrlId }}</th>
                    <td>{{ link.short }}</td>
                    <td>
                        <a :href="link.url">{{ link.url }}</a>
                    </td>
                    <Switch v-model="link.active" @change="updateStatus(link)" />
                    <td>
                        <button type="button" class="btn btn-primary" @click="copyShortUrl(link)">
                            <i class="fa-solid fa-copy"></i>&nbsp;{{ t('general.copy') }}
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <Loading v-else />
    </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import Loading from '@/components/Loading.vue'
import Navigation from '@/components/Navigation.vue';
import { ShortUrlService } from '@/services/short.service';
import { ShortUrlModel } from '@/models/short.model';
import Switch from '@/components/Switch.vue';
import Search from '@/components/Search.vue';
import { closeAnimation, showLoading, showSuccess } from '@/utils';
import { useTranslate } from '@/hooks/translate.hook';

const t = useTranslate()
const shorts = ref<ShortUrlModel[]>()
function loadShorts() {
    ShortUrlService.getAllShortUrls()
        .then(rsp => shorts.value = rsp.data)
}

async function updateStatus(link: ShortUrlModel) {
    showLoading()
    await ShortUrlService.toggleShortUrl({ id: link.shortUrlId, active: link.active })
    closeAnimation()
}

function copyShortUrl(link: ShortUrlModel) {
    const base = process.env.VUE_APP_BASE_SHORT_URL_LINK
    navigator.clipboard.writeText(base + link.short);
    showSuccess(t('short.copy'))
}

onMounted(() => loadShorts())
</script>