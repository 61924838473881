import { ActiveModel } from "@/models/active.model"
import { MainService } from "./main.service"

export class CategoryService {
    public static async getAllCategories(search: string = '') {
        return await MainService.useAxios(`/category?search=${search}`)
    }

    public static async getCategoryById(id: number) {
        return await MainService.useAxios(`/category/${id}`)
    }

    public static async createCategory(payload: Object) {
        return await MainService.useAxios('/category', 'post', payload)
    }

    public static async toggleCategory(payload: ActiveModel) {
        return await MainService.useAxios('/category/toggle', 'put', payload)
    }

    public static async updateCategory(id: number, payload: Object) {
        return await MainService.useAxios('/category/' + id, 'put', payload)
    }
}