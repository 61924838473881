<template>
  <div class="container">
    <router-view />
    <footer class="text-center">
      &copy; {{ year }} {{ name }} | {{ t('footer.made') }} <a href="https://pequla.com">Petar Kresoja</a>
    </footer>
  </div>
</template>

<script lang="ts" setup>
import { useTranslate } from './hooks/translate.hook';

const t = useTranslate()
const year = new Date().getFullYear()
const name = process.env.VUE_APP_BLOG_NAME
</script>
