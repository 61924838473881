import { ActiveModel } from "@/models/active.model";
import { MainService, client } from "./main.service";

export class ImageService {
    public static async getAllImages(search: string) {
        return await MainService.useAxios(`/image?search=${search}`)
    }

    public static async toggleImage(payload: ActiveModel) {
        return await MainService.useAxios('/image/toggle', 'put', payload)
    }

    public static async uploadImage(file: File, image: any) {
        const formData = new FormData();
        formData.append('file', file);

        return await client.request({
            method: 'post',
            url: '/image',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            params: image
        })
    }
}