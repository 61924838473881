<template>
    <button type="button" @click="action()" class="btn btn-success">
        <i class="fa-solid fa-floppy-disk"></i>&nbsp;{{ t('general.save') }}
    </button>
</template>

<script setup lang="ts">
import { useTranslate } from '@/hooks/translate.hook';

const t = useTranslate()

export interface SaveButtonProps {
    action: Function
}

const { action } = defineProps<SaveButtonProps>()
</script>