<template>
    <Navigation />
    <div class="post">
        <h3>{{ t('post.title') }}</h3>
        <Search v-model="search" @change="loadPosts">
            <RouterLink to="/post/new" class="btn btn-primary">
                <i class="fa-solid fa-plus"></i>&nbsp;{{ t('post.add') }}
            </RouterLink>
        </Search>
        <table class="table table-striped table-hover mt-3 mb-3" v-if="posts">
            <thead>
                <tr>
                    <th scope="col">{{ t('post.table.id') }}</th>
                    <th scope="col">{{ t('post.table.permalink') }}</th>
                    <th scope="col">{{ t('post.table.time') }}</th>
                    <th scope="col">{{ t('post.table.active') }}</th>
                    <th scope="col">{{ t('post.table.sticky') }}</th>
                    <th scope="col">{{ t('post.table.action') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="post in posts">
                    <th scope="row">{{ post.postId }}</th>
                    <td>{{ post.permalink }}</td>
                    <td>
                        {{ manageDate(post) }}
                    </td>
                    <Switch v-model="post.active" @change="togglePost(post)" />
                    <Switch v-model="post.sticky" @change="stickyPost(post)" />
                    <td>
                        <div class="btn-group">
                            <RouterLink :to="`/post/edit/${post.postId}`" class="btn btn-primary">
                                <i class="fa-solid fa-pen-to-square"></i>&nbsp;{{ t('general.edit') }}
                            </RouterLink>
                            <RouterLink :to="`/post/edit/${post.postId}/tag`" class="btn btn-secondary">
                                <i class="fa-solid fa-tag"></i>&nbsp;{{ t('post.tag.short') }}
                            </RouterLink>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <Loading v-else />
    </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import { MainService } from '@/services/main.service'
import Loading from '@/components/Loading.vue'
import { PostModel } from '@/models/post.model';
import Navigation from '@/components/Navigation.vue';
import { PostService } from '@/services/post.service';
import Search from '@/components/Search.vue';
import Switch from '@/components/Switch.vue';
import { useTranslate } from '@/hooks/translate.hook';
import { closeAnimation, showLoading } from '@/utils';

const t = useTranslate()
const posts = ref<PostModel[]>()
const search = ref<string>('')
function loadPosts() {
    PostService.getAllPosts(search.value)
        .then(rsp => posts.value = rsp.data)
}

async function togglePost(post: PostModel) {
    showLoading()
    await PostService.togglePost({ id: post.postId, active: post.active })
    closeAnimation()
}

async function stickyPost(post: PostModel) {
    showLoading()
    PostService.stickyPost({ id: post.postId, active: post.sticky })
    closeAnimation()
}

function manageDate(post: PostModel) {
    return (post.displayAt) ? MainService.formatDate(post.displayAt) :
        MainService.formatDate(post.createdAt)
}

onMounted(() => loadPosts())
</script>