<template>
    <Navigation />
    <div class="category">
        <h3>{{ t('category.title') }}</h3>
        <Search v-model="search" @change="loadCategories">
            <RouterLink to="/category/new" class="btn btn-primary">
                <i class="fa-solid fa-plus"></i>&nbsp;{{ t('category.add') }}
            </RouterLink>
        </Search>
        <table class="table table-striped table-hover mt-3 mb-3" v-if="categories">
            <thead>
                <tr>
                    <th scope="col">{{ t('category.table.id') }}</th>
                    <th scope="col">{{ t('category.table.name') }}</th>
                    <th scope="col">{{ t('category.table.permalink') }}</th>
                    <th scope="col">{{ t('category.table.active') }}</th>
                    <th scope="col">{{ t('category.table.action') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="cat in categories">
                    <th scope="row">{{ cat.categoryId }}</th>
                    <td>{{ cat.name }}</td>
                    <td>{{ cat.permalink }}</td>
                    <Switch v-model="cat.active" @change="toggleCategory(cat)" />
                    <td>
                        <RouterLink :to="`/category/edit/${cat.categoryId}`" class="btn btn-primary">
                            <i class="fa-solid fa-pen-to-square"></i>&nbsp;{{ t('general.edit') }}
                        </RouterLink>
                    </td>
                </tr>
            </tbody>
        </table>
        <Loading v-else />
    </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import { CategoryModel } from '@/models/category.model'
import Loading from '@/components/Loading.vue'
import Navigation from '@/components/Navigation.vue';
import { CategoryService } from '@/services/cateogry.service';
import Switch from '@/components/Switch.vue';
import Search from '@/components/Search.vue';
import { useTranslate } from '@/hooks/translate.hook';
import { closeAnimation, showLoading } from '@/utils';

const t = useTranslate()
const categories = ref<CategoryModel[]>()
const search = ref<string>('')
function loadCategories() {
    CategoryService.getAllCategories(search.value)
        .then(rsp => categories.value = rsp.data)
}

async function toggleCategory(category: CategoryModel) {
    showLoading()
    await CategoryService.toggleCategory(
        { id: category.categoryId, active: category.active }
    )
    closeAnimation()
}

onMounted(() => loadCategories())
</script>