import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CategoryList from '@/views/category/CategoryList.vue'
import NewCategory from '@/views/category/NewCategory.vue'
import EditCategory from '@/views/category/EditCategory.vue'
import PostList from '@/views/post/PostList.vue'
import NewPost from '@/views/post/NewPost.vue'
import EditPost from '@/views/post/EditPost.vue'
import AuthorListVue from '@/views/author/AuthorList.vue'
import NewAuthorVue from '@/views/author/NewAuthor.vue'
import EditAuthorVue from '@/views/author/EditAuthor.vue'
import ImageListVue from '@/views/image/ImageList.vue'
import NewImageVue from '@/views/image/NewImage.vue'
import LoginViewVue from '@/views/LoginView.vue'
import TagListVue from '@/views/tag/TagList.vue'
import NewTagVue from '@/views/tag/NewTag.vue'
import EditTagVue from '@/views/tag/EditTag.vue'
import PostTagsVue from '@/views/post/PostTags.vue'
import ShortList from '@/views/short/ShortList.vue'
import NewShort from '@/views/short/NewShort.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: HomeView,
    meta: {
      title: 'Home'
    }
  },
  {
    path: '/login',
    component: LoginViewVue,
    meta: {
      title: 'Login'
    }
  },
  {
    path: '/category',
    component: CategoryList,
    meta: {
      title: 'Categories'
    }
  },
  {
    path: '/category/new',
    component: NewCategory,
    meta: {
      title: 'New Category'
    }
  },
  {
    path: '/category/edit/:id',
    component: EditCategory,
    meta: {
      title: 'Edit Category'
    }
  },
  {
    path: '/author',
    component: AuthorListVue,
    meta: {
      title: 'Authors'
    }
  },
  {
    path: '/author/new',
    component: NewAuthorVue,
    meta: {
      title: 'New Author'
    }
  },
  {
    path: '/author/edit/:id',
    component: EditAuthorVue,
    meta: {
      title: 'Edit Author'
    }
  },
  {
    path: '/post',
    component: PostList,
    meta: {
      title: 'Posts'
    }
  },
  {
    path: '/post/new',
    component: NewPost,
    meta: {
      title: 'New Post'
    }
  },
  {
    path: '/post/edit/:id',
    component: EditPost,
    meta: {
      title: 'Edit Post'
    }
  },
  {
    path: '/post/edit/:id/tag',
    component: PostTagsVue,
    meta: {
      title: 'Edit Tags'
    }
  },
  {
    path: '/image',
    component: ImageListVue,
    meta: {
      title: 'Images'
    }
  },
  {
    path: '/image/new',
    component: NewImageVue,
    meta: {
      title: 'New Image'
    }
  },
  {
    path: '/tag',
    component: TagListVue,
    meta: {
      title: 'Tags'
    }
  },
  {
    path: '/tag/new',
    component: NewTagVue,
    meta: {
      title: 'New Tag'
    }
  },
  {
    path: '/tag/edit/:id',
    component: EditTagVue,
    meta: {
      title: 'Edit Tag'
    }
  },
  {
    path: '/short',
    component: ShortList,
    meta: {
      title: 'Short Urls'
    }
  },
  {
    path: '/short/new',
    component: NewShort,
    meta: {
      title: 'New Short Url'
    }
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title)
    document.title = `${to.meta.title} :: MBC`;
  next();
})

export default router
