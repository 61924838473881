<template>
    <Navigation />
    <div class="author">
        <h3>{{ t('author.edit') }}</h3>
        <RouterLink to="/author" class="btn btn-danger">
            <i class="fa-solid fa-arrow-left"></i>&nbsp;{{ t('author.return') }}
        </RouterLink>
        <div class="mt-3 mb-3" v-if="author">
            <div class="mb-3">
                <label for="idx" class="form-label">{{ t('author.input.id') }}</label>
                <input type="number" class="form-control" id="idx" v-model="author.authorId" disabled>
            </div>
            <div class="mb-3">
                <label for="name" class="form-label">{{ t('author.input.name') }}</label>
                <input type="text" class="form-control" id="name" v-model="author.name">
            </div>
            <div class="mb-3">
                <label for="name" class="form-label">{{ t('author.input.permalink') }}</label>
                <input type="text" class="form-control" id="name" v-model="author.permalink">
            </div>
            <div class="mb-3">
                <label for="desc" class="form-label">{{ t('author.input.desc') }}</label>
                <input type="text" class="form-control" id="desc" v-model="author.description">
            </div>
            <div class="mb-3">
                <label for="avatar" class="form-label">{{ t('author.input.img') }}</label>
                <input type="text" class="form-control" id="avatar" v-model="author.avatar">
            </div>
            <div class="mb-3">
                <label for="web" class="form-label">{{ t('author.input.website') }}</label>
                <input type="text" class="form-control" id="web" v-model="author.website">
            </div>
            <div class="mb-3 form-check">
                <input type="checkbox" class="form-check-input" id="visible" v-model="author.active">
                <label class="form-check-label" for="visible">{{ t('author.input.active') }}</label>
            </div>
            <SaveButton :action="updateAuthor" />
        </div>
    </div>
</template>

<script lang="ts" setup>
import Navigation from '@/components/Navigation.vue';
import SaveButton from '@/components/SaveButton.vue';
import { useTranslate } from '@/hooks/translate.hook';
import { AuthorModel } from '@/models/author.model';
import { AuthorService } from '@/services/author.service';
import { closeAnimation, showError, showLoading } from '@/utils';
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const t = useTranslate()
const router = useRouter();
const route = useRoute()
const id = Number.parseInt(route.params.id.toString())
const author = ref<AuthorModel>()

AuthorService.getAuthorById(id).then(rsp => {
    author.value = rsp.data
})

function updateAuthor() {
    if (author.value == undefined) return
    showLoading()

    if (author.value.permalink == undefined || author.value.permalink == '') {
        showError(t('author.error.permalink'))
        return
    }

    if (author.value.name == undefined || author.value.name == '') {
        showError(t('author.error.name'))
        return
    }

    if (author.value.description == undefined || author.value.description == '') {
        showError(t('author.error.desc'))
        return
    }

    if (author.value.avatar == undefined || author.value.avatar == '') {
        showError(t('author.error.img'))
        return
    }

    if (author.value.website == undefined || author.value.website == '') {
        showError(t('author.error.website'))
        return
    }

    AuthorService.updateAuthor(id, {
        permalink: author.value.permalink,
        name: author.value.name,
        description: author.value.description,
        avatar: author.value.avatar,
        website: author.value.website,
        active: author.value.active
    }).then(rsp => {
        closeAnimation()
        router.push({
            path: '/author'
        })
    }).catch(e => alert(e.message))
}
</script>