import axios, { AxiosError, AxiosResponse } from "axios";
import { TokenService } from "./token.service";

export const client = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    withCredentials: false,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});

export class MainService {
    public static formatDate(timestamp: string | null) {
        if (timestamp == null) {
            return 'N/A'
        }
        return new Date(timestamp).toLocaleString('sr')
    }

    public static async login(username: string, password: string) {
        return await client.request({
            url: '/admin/login',
            method: 'post',
            data: {
                username: username,
                password: password
            }
        })
    }

    public static async useAxios(url: string, method: string = 'GET', body: object = {}) {
        // Attempting to retrieve data
        let rsp: AxiosResponse;

        try {
            rsp = await client.request({
                url: url,
                method: method,
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${TokenService.getAccessToken()}`
                },
                data: body
            }) as AxiosResponse;
        } catch (e) {
            rsp = (e as AxiosError).response as AxiosResponse
        }

        if (rsp == undefined) {
            TokenService.clearAuth()
            window.location.href = '/login'
            throw new Error('BACKEND_UNREACHABLE')
        }

        if (rsp.status == 403) {
            // Refresh token
            try {
                const tokenReq = await client.request({
                    url: '/admin/refresh',
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${TokenService.getRefreshToken()}`
                    },
                    data: body
                })

                // Save token
                TokenService.saveAuth(tokenReq.data)

                // Repeating the primary request
                return await client.request({
                    url: url,
                    method: method,
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${TokenService.getAccessToken()}`
                    },
                    data: body
                });
            } catch (e) {
                TokenService.clearAuth()
                window.location.href = '/login'
                throw new Error('REFRESH_FAILED')
            }
        }

        if (rsp.status == 404) {
            throw new Error('NOT_FOUND')
        }

        // Return response
        return rsp;
    }

    public static async getSelf() {
        return await this.useAxios('/admin/self')
    }
}
