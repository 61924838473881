<template>
    <Navigation />
    <div class="post" v-if="post">
        <h3>{{ t('post.tag.title') }}</h3>
        <h5>
            <RouterLink :to="`/post/edit/${post.postId}`">{{ post.title }}</RouterLink>
        </h5>
        <RouterLink to="/post" class="btn btn-danger"><i class="fa-solid fa-arrow-left"></i>
            {{ t('post.return') }}
        </RouterLink>
        <div class="mt-3" v-if="all && tags">
            <div class="mb-3 form-check form-switch" v-for="tag in all">
                <input class="form-check-input" type="checkbox" role="switch" :id="tag.permalink" :checked="hasTag(tag)"
                    @change="manageTag(tag)">
                <label class="form-check-label" :for="tag.permalink">
                    {{ tag.name }}
                </label>
            </div>
            <div class="alert alert-primary" role="alert">
                <i class="fa-solid fa-circle-info"></i>&nbsp;{{ t('post.tag.info') }}
            </div>
        </div>
        <Loading v-else />
    </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import Loading from '@/components/Loading.vue'
import Navigation from '@/components/Navigation.vue';
import { TagModel } from '@/models/tag.model';
import { TagService } from '@/services/tag.service';
import { PostModel } from '@/models/post.model';
import { PostService } from '@/services/post.service';
import { RouterLink, useRoute } from 'vue-router';
import { useTranslate } from '@/hooks/translate.hook';
import { closeAnimation, showLoading } from '@/utils';

const t = useTranslate()
const route = useRoute()
const id = Number.parseInt(route.params.id.toString())
const post = ref<PostModel>()
const all = ref<TagModel[]>()
const tags = ref<TagModel[]>()

PostService.getSimplePostById(id)
    .then(rsp => post.value = rsp.data)

TagService.getAllTags()
    .then(rsp => all.value = rsp.data)

PostService.getPostTagsByPostId(id)
    .then(rsp => tags.value = rsp.data)

function hasTag(tag: TagModel) {
    if (tags.value == undefined) return false
    return tags.value.map(t => t.tagId).includes(tag.tagId)
}

function manageTag(tag: TagModel) {
    showLoading()
    if (hasTag(tag)) {
        PostService.removePostTag(id, tag.tagId).then(rsp => {
            tags.value = tags.value?.filter(t => t.tagId != tag.tagId)
            closeAnimation()
        })
        return
    }

    PostService.addPostTag(id, tag.tagId).then(rsp => {
        tags.value?.push(tag)
        closeAnimation()
    })
}
</script>