<template>
    <div class="mb-3">
        <label for="editor" class="form-label">{{ t('general.content') }}</label>
        <QuillEditor theme="snow" content-type="html" v-model:content="model" id="editor" :toolbar="quillToolbar"
            :modules="modules" />
    </div>
</template>

<script setup lang="ts">
import { useTranslate } from '@/hooks/translate.hook';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { htmlEditButton } from 'quill-html-edit-button';
import ImageCompress from 'quill-image-compress'

const t = useTranslate()
const model = defineModel<string>()

const quillToolbar = {
    container: [
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [
            { header: [false, 1, 2, 3, 4, 5, 6] }
        ],
        [
            { list: 'ordered' },
            { list: 'bullet' }
        ],
        [
            { align: '' },
            { align: 'center' },
            { align: 'right' },
            { align: 'justify' }
        ],
        ['link', 'image']
    ]
}

const modules = [{
    name: 'htmlEditButton',
    module: htmlEditButton,
    options: {
        msg: "Izmeni izvorni kod",
        okText: "Sačuvaj",
        cancelText: "Odustani",
        buttonHTML: '<i class="fa-solid fa-code"></i>',
        buttonTitle: "Izmeni izvorni kod",
        syntax: true
    }
},
{
    name: 'imageCompress',
    module: ImageCompress,
    options: {
        quality: 0.8,
        maxWidth: 1280,
        maxHeight: 720,
        imageType: 'image/jpeg'
    }
}]

</script>