<template>
    <div class="mx-auto text-center mt-3">
        <img src="@/assets/loading.gif" alt="Loading Animation" class="loading mb-3">
        <p class="mb-3 h4">{{ t('general.loading') }}</p>
    </div>
</template>

<script lang="ts" setup>
import { useTranslate } from '@/hooks/translate.hook';

const t = useTranslate()
</script>

<style>
.loading {
    width: 300px;
}
</style>