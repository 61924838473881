<template>
    <Navigation />
    <div class="image">
        <h3>{{ t('image.add') }}</h3>
        <RouterLink to="/image" class="btn btn-danger">
            <i class="fa-solid fa-arrow-left"></i>&nbsp;{{ t('image.return') }}
        </RouterLink>
        <div class="mt-3 mb-3" v-if="image">
            <div class="mb-3">
                <label for="name" class="form-label">{{ t('image.input.name') }}</label>
                <input type="text" class="form-control" id="name" v-model="image.name">
            </div>
            <div class="mb-3">
                <label for="file" class="form-label">{{ t('image.input.img') }}</label>
                <input class="form-control" type="file" id="file" accept="image/*" v-on:change="updateFile">
                <p v-if="file" :class="(file.size > MAX_FILE_SIZE) ? 'text-danger fw-bold' : ''">
                    {{ `${t('image.input.size.0')}${(file.size > MAX_FILE_SIZE) ? ` ${t('image.input.size.1')}` : ''}:
                    ${formatSize(file.size)}` }}
                </p>
            </div>
            <div class="mb-3 form-check">
                <input type="checkbox" class="form-check-input" id="visible" v-model="image.active">
                <label class="form-check-label" for="visible">{{ t('image.input.active') }}</label>
            </div>
            <SaveButton :action="uploadImage" />
        </div>
    </div>
</template>

<script setup lang="ts">
import Navigation from '@/components/Navigation.vue';
import SaveButton from '@/components/SaveButton.vue';
import { useTranslate } from '@/hooks/translate.hook';
import { ImageService } from '@/services/image.service';
import { closeAnimation, showError, showLoading } from '@/utils';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const t = useTranslate()
const MAX_FILE_SIZE = process.env.VUE_APP_MAX_FILE_SIZE
const image = ref<any>({
    name: "",
    active: true
})
const file = ref<File>()
function updateFile(event: any) {
    if (event.target.files.lenght == 0)
        return

    file.value = event.target.files[0]
}

const router = useRouter()
async function uploadImage() {
    if (file.value == undefined) {
        showError(t('image.error.upload'))
        return
    }

    if (file.value.size > MAX_FILE_SIZE) {
        showError(t('image.error.oversized'))
        return
    }

    if (image.value.name == '') {
        showError(t('image.error.name'))
        return
    }

    try {
        showLoading()
        await ImageService.uploadImage(file.value, image)
        closeAnimation()
        router.push({
            path: '/image'
        })
    } catch (e) {
        showError(t('image.error.general'))
    }
}

function formatSize(size: number) {
    if (size >= 1000000) return `${Math.round(size / 10000) / 100} MB`
    if (size >= 1000) return `${Math.round(size / 10) / 100} KB`
    return `${size} B`
}

</script>