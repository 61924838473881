<template>
    <Navigation />
    <div class="post">
        <h3>{{ t('post.add') }}</h3>
        <RouterLink to="/post" class="btn btn-danger">
            <i class="fa-solid fa-arrow-left"></i>&nbsp;{{ t('post.return') }}
        </RouterLink>
        <div class="mt-3 mb-3" v-if="post">
            <div class="mb-3">
                <label for="title" class="form-label">{{ t('post.input.title') }}</label>
                <input type="text" class="form-control" id="title" v-model="post.title" @keyup="generatePermalink">
            </div>
            <div class="mb-3">
                <label for="permalink" class="form-label">{{ t('post.input.permalink') }}</label>
                <input type="text" class="form-control" id="permalink" v-model="post.permalink">
            </div>
            <div class="mb-3">
                <label for="thumbnail" class="form-label">{{ t('post.input.img') }}</label>
                <input type="text" class="form-control" id="thumbnail" v-model="post.thumbnail">
            </div>
            <div class="mb-3">
                <label for="keywords" class="form-label">{{ t('post.input.keywords') }}</label>
                <input type="text" class="form-control" id="keywords" v-model="post.keywords">
            </div>
            <div class="mb-3">
                <label for="desc" class="form-label">{{ t('post.input.desc') }}</label>
                <input type="text" class="form-control" id="desc" v-model="post.description">
            </div>
            <TextEditor v-model="post.content" />
            <div class="mb-3">
                <label for="cat" class="form-label">{{ t('post.input.category') }}</label>
                <select class="form-select" v-model="post.category" id="cat">
                    <option v-for="cat in categories" :value="cat.categoryId">{{ cat.name }}</option>
                </select>
            </div>
            <div class="mb-3">
                <label for="author" class="form-label">{{ t('post.input.author') }}</label>
                <select class="form-select" v-model="post.author" id="author">
                    <option v-for="author in authors" :value="author.authorId">{{ author.name }}</option>
                </select>
            </div>
            <div class="mb-3">
                <label for="display" class="form-label">{{ t('post.input.date') }}</label>
                <VueDatePicker v-model="post.displayAt" :format="formatDate" id="display" />
            </div>
            <div class="mb-3 form-check">
                <input type="checkbox" class="form-check-input" id="visible" v-model="post.visible">
                <label class="form-check-label" for="visible">{{ t('post.input.active') }}</label>
            </div>
            <div class="mb-3 form-check">
                <input type="checkbox" class="form-check-input" id="sticky" v-model="post.sticky">
                <label class="form-check-label" for="sticky">{{ t('post.input.sticky') }}</label>
            </div>
            <SaveButton :action="createPost" />
        </div>
    </div>
</template>

<script lang="ts" setup>
import Navigation from '@/components/Navigation.vue';
import { AuthorModel } from '@/models/author.model';
import { CategoryModel } from '@/models/category.model';
import { AuthorService } from '@/services/author.service';
import { CategoryService } from '@/services/cateogry.service';
import { PostService } from '@/services/post.service';
import { closeAnimation, formatPermalink, showLoading } from '@/utils';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import TextEditor from '@/components/TextEditor.vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { useTranslate } from '@/hooks/translate.hook';
import SaveButton from '@/components/SaveButton.vue';
import { formatDate } from '@/utils';

const t = useTranslate()
const post = ref<any>({
    title: "",
    permalink: "",
    thumbnail: "",
    keywords: "",
    description: "",
    content: "",
    category: 1,
    author: 1,
    displayAt: "",
    visible: true,
    sticky: false
})

const categories = ref<CategoryModel[]>()
CategoryService.getAllCategories().then(rsp => {
    categories.value = rsp.data
    post.value.category = rsp.data[0].categoryId
})

const authors = ref<AuthorModel[]>()
AuthorService.getAllAuthors().then(rsp => {
    authors.value = rsp.data
    post.value.author = rsp.data[0].authorId
})

const router = useRouter()
function createPost() {
    showLoading()
    if (post.value.displayAt == '')
        post.value.displayAt = null

    PostService.createPost(post.value).then(rsp => {
        closeAnimation()
        router.push({
            path: '/post'
        })
    })
}

function generatePermalink() {
    post.value.permalink = formatPermalink(post.value.title)
}
</script>