import { createI18n } from "vue-i18n";
import { nextTick } from "vue";

const i18n = createI18n({
    locale: 'sr',
    fallbackLocale: 'en',
    legacy: false
})

export class TranslateService {
    static getI18n() {
        return i18n
    }

    static async setLocale(locale: string) {
        try {
            const messages = await import(`@/locales/${locale}.json`)
            i18n.global.setLocaleMessage(locale, messages.default)
            i18n.global.locale.value = locale
            localStorage.setItem('language', locale)
        } catch (e) {
            console.error(e)
        } finally {
            return nextTick()
        }
    }
}