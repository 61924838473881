import { MainService } from "./main.service"

export class ShortUrlService {
    public static async getAllShortUrls() {
        return await MainService.useAxios('/short')
    }

    public static async createShortUrl(payload: Object) {
        return await MainService.useAxios('/short', 'post', payload)
    }

    public static async toggleShortUrl(payload: Object) {
        return await MainService.useAxios('/short', 'put', payload)
    }
}