<template>
    <Navigation />
    <div class="author">
        <h3>{{ t('author.title') }}</h3>
        <Search v-model="search" @change="loadAuthors">
            <RouterLink to="/author/new" class="btn btn-primary">
                <i class="fa-solid fa-plus"></i>&nbsp;{{ t('author.add') }}
            </RouterLink>
        </Search>
        <table class="table table-striped table-hover mt-3 mb-3" v-if="authors">
            <thead>
                <tr>
                    <th scope="col">{{ t('author.table.id') }}</th>
                    <th scope="col">{{ t('author.table.name') }}</th>
                    <th scope="col">{{ t('author.table.permalink') }}</th>
                    <th scope="col">{{ t('author.table.active') }}</th>
                    <th scope="col">{{ t('author.table.action') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="author in authors">
                    <th scope="row">{{ author.authorId }}</th>
                    <td>{{ author.name }}</td>
                    <td>{{ author.permalink }}</td>
                    <Switch v-model="author.active" @change="toggleAuthor(author)" />
                    <td>
                        <RouterLink :to="`/author/edit/${author.authorId}`" class="btn btn-primary">
                            <i class="fa-solid fa-pen-to-square"></i>&nbsp;{{ t('general.edit') }}
                        </RouterLink>
                    </td>
                </tr>
            </tbody>
        </table>
        <Loading v-else />
    </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import { AuthorModel } from '@/models/author.model'
import Loading from '@/components/Loading.vue'
import Navigation from '@/components/Navigation.vue';
import { AuthorService } from '@/services/author.service';
import Search from '@/components/Search.vue';
import Switch from '@/components/Switch.vue';
import { useTranslate } from '@/hooks/translate.hook';
import { closeAnimation, showLoading } from '@/utils';

const t = useTranslate()
const authors = ref<AuthorModel[]>()
const search = ref<string>('')
function loadAuthors() {
    AuthorService.getAllAuthors(search.value)
        .then(rsp => authors.value = rsp.data)
}

async function toggleAuthor(author: AuthorModel) {
    showLoading()
    await AuthorService.toggleAuthor({ id: author.authorId, active: author.active })
    closeAnimation()
}

onMounted(() => loadAuthors())
</script>