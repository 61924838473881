import { ActiveModel } from "@/models/active.model"
import { MainService } from "./main.service"

export class PostService {
    public static async getAllPosts(search: string) {
        return await MainService.useAxios(`/post?search=${search}`)
    }

    public static async getSimplePostById(id: number) {
        return await MainService.useAxios(`/post/${id}/simple`)
    }

    public static async getPostById(id: number) {
        return await MainService.useAxios(`/post/${id}`)
    }

    public static async getPostTagsByPostId(id: number) {
        return await MainService.useAxios(`/post/${id}/tag-list`)
    }

    public static async addPostTag(post: number, tag: number) {
        return await MainService.useAxios(`/post/${post}/add-tag/${tag}`, 'post')
    }

    public static async removePostTag(post: number, tag: number) {
        return await MainService.useAxios(`/post/${post}/remove-tag/${tag}`, 'delete')
    }

    public static async createPost(payload: Object) {
        return await MainService.useAxios('/post', 'post', payload)
    }

    public static async togglePost(payload: ActiveModel) {
        return await MainService.useAxios('/post/toggle', 'put', payload)
    }

    public static async stickyPost(payload: ActiveModel) {
        return await MainService.useAxios('/post/sticky', 'put', payload)
    }

    public static async updatePost(id: number, payload: Object) {
        return await MainService.useAxios(`/post/${id}`, 'put', payload)
    }
}