<template>
    <Navigation />
    <div class="post" v-if="post">
        <h3>{{ t('post.edit') }}</h3>
        <div class="btn-group">
            <RouterLink to="/post" class="btn btn-danger">
                <i class="fa-solid fa-arrow-left"></i>&nbsp;{{ t('post.return') }}
            </RouterLink>
            <a class="btn btn-primary" :href="previewBase + post.postId" target="_blank">
                <i class="fa-solid fa-arrow-up-right-from-square"></i>&nbsp;{{ t('post.preview') }}
            </a>
        </div>
        <div class="mt-3 mb-3">
            <div class="mb-3">
                <label for="idx" class="form-label">{{ t('post.input.id') }}</label>
                <input type="number" class="form-control" id="idx" v-model="post.postId" disabled>
            </div>
            <div class="mb-3">
                <label for="title" class="form-label">{{ t('post.input.title') }}</label>
                <input type="text" class="form-control" id="title" v-model="post.title">
            </div>
            <div class="mb-3">
                <label for="permalink" class="form-label">{{ t('post.input.permalink') }}</label>
                <input type="text" class="form-control" id="permalink" v-model="post.permalink">
            </div>
            <div class="mb-3">
                <label for="thumbnail" class="form-label">{{ t('post.input.img') }}</label>
                <input type="text" class="form-control" id="thumbnail" v-model="post.thumbnail">
            </div>
            <div class="mb-3">
                <label for="keywords" class="form-label">{{ t('post.input.keywords') }}</label>
                <input type="text" class="form-control" id="keywords" v-model="post.keywords">
            </div>
            <div class="mb-3">
                <label for="desc" class="form-label">{{ t('post.input.desc') }}</label>
                <input type="text" class="form-control" id="desc" v-model="post.description">
            </div>
            <TextEditor v-model="post.content" />
            <div class="mb-3">
                <label for="cat" class="form-label">{{ t('post.input.category') }}</label>
                <select class="form-select" v-model="post.categoryId" id="cat">
                    <option v-for="cat in categories" :value="cat.categoryId">{{ cat.name }}</option>
                </select>
            </div>
            <div class="mb-3">
                <label for="author" class="form-label">{{ t('post.input.author') }}</label>
                <select class="form-select" v-model="post.authorId" id="author">
                    <option v-for="author in authors" :value="author.authorId">{{ author.name }}</option>
                </select>
            </div>
            <div class="mb-3">
                <label for="display" class="form-label">{{ t('post.input.date') }}</label>
                <VueDatePicker v-model="post.displayAt" :format="formatDate" id="display" />
            </div>
            <div class="mb-3 form-check">
                <input type="checkbox" class="form-check-input" id="visible" v-model="post.active">
                <label class="form-check-label" for="visible">{{ t('post.input.active') }}</label>
            </div>
            <div class="mb-3 form-check">
                <input type="checkbox" class="form-check-input" id="sticky" v-model="post.sticky">
                <label class="form-check-label" for="sticky">{{ t('post.input.sticky') }}</label>
            </div>
            <SaveButton :action="updatePost" />
        </div>
    </div>
    <Loading v-else />
</template>

<script lang="ts" setup>
import { PostModel } from '@/models/post.model';
import Loading from '@/components/Loading.vue'
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { CategoryModel } from '@/models/category.model';
import { AuthorModel } from '@/models/author.model';
import Navigation from '@/components/Navigation.vue';
import TextEditor from '@/components/TextEditor.vue';
import { PostService } from '@/services/post.service';
import { CategoryService } from '@/services/cateogry.service';
import { AuthorService } from '@/services/author.service';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { closeAnimation, formatDate, showLoading } from '@/utils'
import { useTranslate } from '@/hooks/translate.hook';
import SaveButton from '@/components/SaveButton.vue';

const t = useTranslate()
const previewBase = process.env.VUE_APP_POST_PREVIEW_BASE_URL
const post = ref<PostModel>()
const categories = ref<CategoryModel[]>()
const authors = ref<AuthorModel[]>()
const router = useRouter();
const route = useRoute()
const id = Number.parseInt(route.params.id.toString())

PostService.getPostById(id).then(rsp => {
    post.value = rsp.data
})

CategoryService.getAllCategories().then(rsp => {
    categories.value = rsp.data
})

AuthorService.getAllAuthors().then(rsp => {
    authors.value = rsp.data
})

function updatePost() {
    if (post.value == undefined) return
    showLoading()

    PostService.updatePost(id, {
        title: post.value.title,
        permalink: post.value.permalink,
        thumbnail: post.value.thumbnail,
        keywords: post.value.keywords,
        description: post.value.description,
        content: post.value.content,
        category: post.value.categoryId,
        author: post.value.authorId,
        displayAt: post.value.displayAt,
        visible: post.value.active
    }).then(rsp => {
        closeAnimation()
        router.push({
            path: '/post'
        })
    })
}
</script>