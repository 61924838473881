<template>
    <Navigation :show="false" />
    <div class="login">
        <h3 class="mt-5 text-center mb-3">
            {{ name }}<br>{{ t('login.title') }}
        </h3>
        <form class="mx-auto form-responsive" v-on:submit.prevent="doLogin">
            <div class="mb-3">
                <label for="username" class="form-label">{{ t('login.username') }}</label>
                <input type="text" class="form-control" id="username" v-model="username">
            </div>
            <div class="mb-3">
                <label for="password" class="form-label">{{ t('login.password') }}</label>
                <input type="password" class="form-control" id="password" v-model="password">
            </div>
            <button type="submit" class="btn btn-primary mb-3">{{ t('general.send') }}</button>
        </form>
    </div>
</template>

<script setup lang="ts">
import Navigation from '@/components/Navigation.vue';
import { useTranslate } from '@/hooks/translate.hook';
import { MainService } from '@/services/main.service';
import { TokenService } from '@/services/token.service';
import { closeAnimation, showError, showLoading } from '@/utils';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const t = useTranslate()
const username = ref<string>()
const password = ref<string>()
const router = useRouter()

const name = process.env.VUE_APP_BLOG_NAME || "LOGIN PAGE"

function doLogin() {
    if (username.value == undefined || password.value == undefined) return
    if (username.value == '' || password.value == '') return

    MainService.login(username.value, password.value).then(rsp => {
        showLoading()
        TokenService.saveAuth(rsp.data)
        router.push({
            path: '/'
        })
        closeAnimation()
    }).catch(e => {
        showError(t('login.error'))
    })
}
</script>

<style>
.form-responsive {
    width: 50%;
}

@media (max-width: 900px) {
  .form-responsive {
    width: 100%;
  }
}
</style>