<template>
    <Navigation />
    <div class="author">
        <h3>{{ t('author.add') }}</h3>
        <RouterLink to="/author" class="btn btn-danger">
            <i class="fa-solid fa-arrow-left"></i>&nbsp;{{ t('author.return') }}
        </RouterLink>
        <div class="mt-3 mb-3">
            <div class="mb-3">
                <label for="name" class="form-label">{{ t('author.input.name') }}</label>
                <input type="text" class="form-control" id="name" v-model="author.name" @keyup="generatePermalink">
            </div>
            <div class="mb-3">
                <label for="name" class="form-label">{{ t('author.input.permalink') }}</label>
                <input type="text" class="form-control" id="name" v-model="author.permalink">
            </div>
            <div class="mb-3">
                <label for="desc" class="form-label">{{ t('author.input.desc') }}</label>
                <input type="text" class="form-control" id="desc" v-model="author.description">
            </div>
            <div class="mb-3">
                <label for="avatar" class="form-label">{{ t('author.input.img') }}</label>
                <input type="text" class="form-control" id="avatar" v-model="author.avatar">
            </div>
            <div class="mb-3">
                <label for="web" class="form-label">{{ t('author.input.website') }}</label>
                <input type="text" class="form-control" id="web" v-model="author.website">
            </div>
            <div class="mb-3 form-check">
                <input type="checkbox" class="form-check-input" id="visible" v-model="author.active">
                <label class="form-check-label" for="visible">{{ t('author.input.active') }}</label>
            </div>
            <SaveButton :action="createAuthor" />
        </div>
    </div>
</template>

<script lang="ts" setup>
import Navigation from '@/components/Navigation.vue';
import SaveButton from '@/components/SaveButton.vue';
import { useTranslate } from '@/hooks/translate.hook';
import { AuthorService } from '@/services/author.service';
import { closeAnimation, formatPermalink, showError, showLoading } from '@/utils';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const author = ref<any>({
    permalink: "",
    name: "",
    description: "",
    avatar: "",
    website: "",
    active: true,
})

const t = useTranslate();
const router = useRouter();
function createAuthor() {
    showLoading()
    if (author.value.permalink == undefined || author.value.permalink == '') {
        showError(t('author.error.permalink'))
        return
    }

    if (author.value.name == undefined || author.value.name == '') {
        showError(t('author.error.name'))
        return
    }

    if (author.value.description == undefined || author.value.description == '') {
        showError(t('author.error.desc'))
        return
    }

    if (author.value.avatar == undefined || author.value.avatar == '') {
        showError(t('author.error.img'))
        return
    }

    if (author.value.website == undefined || author.value.website == '') {
        showError(t('author.error.website'))
        return
    }

    AuthorService.createAuthor(author.value)
        .then(rsp => {
            closeAnimation()
            router.push({
                path: '/author'
            })
        })
}

function generatePermalink() {
    author.value.permalink = formatPermalink(author.value.name)
}
</script>