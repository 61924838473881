import { ActiveModel } from "@/models/active.model"
import { MainService } from "./main.service"

export class AuthorService {
    public static async getAllAuthors(search: string = '') {
        return await MainService.useAxios(`/author?search=${search}`)
    }

    public static async getAuthorById(id: number) {
        return await MainService.useAxios(`/author/${id}`)
    }

    public static async createAuthor(payload: Object) {
        return await MainService.useAxios('/author', 'post', payload)
    }

    public static async toggleAuthor(payload: ActiveModel) {
        return await MainService.useAxios('/author/toggle', 'put', payload)
    }

    public static async updateAuthor(id: number, payload: Object) {
        return await MainService.useAxios('/author/' + id, 'put', payload)
    }
}