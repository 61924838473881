<template>
    <td>
        <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" role="switch" v-model="model" @change="handleChange">
            <label class="form-check-label" for="flexSwitchCheckDefault">
                <span v-if="model">{{ t('general.yes') }}</span>
                <span v-else>{{ t('general.no') }}</span>
            </label>
        </div>
    </td>
</template>

<script setup lang="ts">
import { useTranslate } from '@/hooks/translate.hook';

const t = useTranslate()
const model = defineModel<boolean>()
const emits = defineEmits(['change']);

function handleChange() {
    emits('change', model.value);
}
</script>