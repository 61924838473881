<template>
    <nav class="navbar navbar-expand-lg">
        <div class="container">
            <RouterLink class="navbar-brand" to="/"><i class="fa-solid fa-file"></i> {{ name }}</RouterLink>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0" v-if="props.show">
                    <li class="nav-item">
                        <RouterLink class="nav-link" to="/">
                            {{ t('nav.home') }}
                        </RouterLink>
                    </li>
                    <li class="nav-item">
                        <RouterLink class="nav-link" to="/post">
                            {{ t('nav.post') }}
                        </RouterLink>
                    </li>
                    <li class="nav-item">
                        <RouterLink class="nav-link" to="/category">
                            {{ t('nav.category') }}
                        </RouterLink>
                    </li>
                    <li class="nav-item">
                        <RouterLink class="nav-link" to="/tag">
                            {{ t('nav.tag') }}
                        </RouterLink>
                    </li>
                    <li class="nav-item">
                        <RouterLink class="nav-link" to="/author">
                            {{ t('nav.author') }}
                        </RouterLink>
                    </li>
                    <li class="nav-item">
                        <RouterLink class="nav-link" to="/image">
                            {{ t('nav.image') }}
                        </RouterLink>
                    </li>
                    <li class="nav-item">
                        <RouterLink class="nav-link" to="/short">
                            {{ t('nav.short') }}
                        </RouterLink>
                    </li>
                    <li class="nav-item">
                        <button class="nav-link" @click="logout">
                            {{ t('nav.logout') }}
                        </button>
                    </li>
                </ul>
            </div>
            <div class="navbar-text">
                <select class="form-select" aria-label="Default select example" v-model="lang"
                    @change="handleLangChange">
                    <option value="sr">Srpski</option>
                    <option value="en">English</option>
                </select>
                <div class="form-check form-switch">
                    <label for="darkModeSwitch"><i class="fa-solid fa-moon"></i></label>
                    <input id="darkModeSwitch" type="checkbox" class="form-check-input" v-model="darkMode"
                        @change="toggleDarkMode">
                </div>
            </div>
        </div>
    </nav>
</template>

<script setup lang="ts">
import { useTranslate } from '@/hooks/translate.hook';
import { TokenService } from '@/services/token.service';
import { TranslateService } from '@/services/translate.service';
import { showConfirm } from '@/utils';
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

const name = process.env.VUE_APP_BLOG_NAME
const router = useRouter()

const props = defineProps({
    show: {
        type: Boolean,
        default: true,
        required: false
    }
})

const lang = ref<string>('sr')

function handleLangChange() {
    TranslateService.setLocale(lang.value)
}

const t = useTranslate()
function logout() {
    showConfirm(t('alert.logout.0'), t('alert.logout.1'), () => {
        TokenService.clearAuth()
        router.push({
            path: '/login'
        })
    })
}

const darkMode = ref<boolean>(false);

onMounted(() => {
    const savedMode = localStorage.getItem('darkMode');
    if (savedMode !== null) {
        darkMode.value = JSON.parse(savedMode);
    } else {
        darkMode.value = true
    }
    toggleDarkMode();

    const savedLang = localStorage.getItem('language');
    if (savedLang !== null) {
        lang.value = savedLang;
    } else {
        lang.value = 'sr'
    }
    handleLangChange();
});

function toggleDarkMode() {
    document.documentElement.setAttribute('data-bs-theme', (darkMode.value) ? 'dark' : 'light')
    localStorage.setItem('darkMode', JSON.stringify(darkMode.value));
};
</script>

<style>
.navbar-text {
  display: flex;
  align-items: center;
  gap: 8px;
}
</style>