<template>
    <Navigation />
    <div class="short">
        <h3>{{ t('short.add') }}</h3>
        <RouterLink to="/short" class="btn btn-danger">
            <i class="fa-solid fa-arrow-left"></i>{{ t('short.return') }}
        </RouterLink>
        <div class="mt-3 mb-3">
            <div class="mb-3">
                <label for="short" class="form-label">{{ t('short.input.short') }}</label>
                <input type="text" class="form-control" id="short" v-model="short">
            </div>
            <div class="mb-3">
                <label for="url" class="form-label">{{ t('short.input.to') }}</label>
                <input type="text" class="form-control" id="url" v-model="url">
            </div>
            <div class="mb-3 form-check">
                <input type="checkbox" class="form-check-input" id="visible" v-model="active">
                <label class="form-check-label" for="visible">{{ t('short.input.active') }}</label>
            </div>
            <SaveButton :action="save" />
        </div>
    </div>
</template>

<script setup lang="ts">
import Navigation from '@/components/Navigation.vue';
import SaveButton from '@/components/SaveButton.vue';
import { useTranslate } from '@/hooks/translate.hook';
import { ShortUrlService } from '@/services/short.service';
import { closeAnimation, showLoading } from '@/utils';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const t = useTranslate()
const router = useRouter()
const short = ref<string>('')
const url = ref<string>('')
const active = ref<boolean>(true)

function save() {
    showLoading()
    ShortUrlService.createShortUrl({
        short: short.value,
        url: url.value,
        active: active.value
    }).then(rsp => {
        closeAnimation()
        router.push({
            path: '/short'
        })
    })
}
</script>