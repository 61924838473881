<template>
    <Navigation />
    <div class="category">
        <h3>{{ t('category.add') }}</h3>
        <RouterLink to="/category" class="btn btn-danger">
            <i class="fa-solid fa-arrow-left"></i>&nbsp;{{ t('category.return') }}
        </RouterLink>
        <div class="mt-3 mb-3">
            <div class="mb-3">
                <label for="name" class="form-label">{{ t('category.input.name') }}</label>
                <input type="text" class="form-control" id="name" v-model="category.name" @keyup="generatePermalink">
            </div>
            <div class="mb-3">
                <label for="name" class="form-label">{{ t('category.input.permalink') }}</label>
                <input type="text" class="form-control" id="name" v-model="category.permalink">
            </div>
            <div class="mb-3">
                <label for="kw" class="form-label">{{ t('category.input.keywords') }}</label>
                <input type="text" class="form-control" id="kw" v-model="category.keywords">
            </div>
            <div class="mb-3">
                <label for="desc" class="form-label">{{ t('category.input.desc') }}</label>
                <input type="text" class="form-control" id="desc" v-model="category.description">
            </div>
            <div class="mb-3 form-check">
                <input type="checkbox" class="form-check-input" id="visible" v-model="category.active">
                <label class="form-check-label" for="visible">{{ t('category.input.active') }}</label>
            </div>
            <SaveButton :action="createCategory" />
        </div>
    </div>
</template>

<script lang="ts" setup>
import Navigation from '@/components/Navigation.vue';
import SaveButton from '@/components/SaveButton.vue';
import { useTranslate } from '@/hooks/translate.hook';
import { CategoryService } from '@/services/cateogry.service';
import { closeAnimation, formatPermalink, showError, showLoading } from '@/utils';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const category = ref<any>({
    permalink: "",
    name: "",
    keywords: "",
    description: "",
    active: true,
})

const t = useTranslate()
const router = useRouter();
function createCategory() {
    showLoading()
    if (category.value.permalink == undefined || category.value.permalink == '') {
        showError(t('category.error.permalink'))
        return
    }

    if (category.value.name == undefined || category.value.name == '') {
        showError(t('category.error.name'))
        return
    }

    if (category.value.description == undefined || category.value.description == '') {
        showError(t('category.error.desc'))
        return
    }

    if (category.value.keywords == undefined || category.value.keywords == '') {
        showError(t('category.error.keywords'))
        return
    }

    CategoryService.createCategory(category.value).then(rsp => {
        closeAnimation()
        router.push({
            path: '/category'
        })
    })
}

function generatePermalink() {
    if (category.value.permalink == undefined) return
    category.value.permalink = formatPermalink(category.value.name)
}
</script>